import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IMikeTheme } from '../../shared/mikeSharedTheme'
import {  pointUpdate,  renamePoints,  replacePoints,  setExistingPointNames,  uploadPoints, uploadPointsUrlSet } from '../../actions/mapContent' 
import { IState } from '../../reducers';
import { IGetProject } from '../../model/IGetProject'
import EditPointsForm from '../EditPointsForm';
import { IValue } from '../EditPointsForm/EditPointsForm';
import UploadButton from '../UploadButton/UploadButton';
import { titleContainer, titleStyle, subTitleStyle } from '../../shared/styles';
import { useIntl } from 'react-intl';
import MikeDialog, { contentTitleStyle, dialogActionsStyle, dialogContentStyle, mediumButtonStyle } from '../DialogComponents/MikeDialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MikeButton from '../mike-button/MikeButton';
import ContextHelp from '../ContextHelp';
import { EGeometryItemTypes, KeyboardEditHelp } from '../Viewer/keyboard-edit-help';
import { css } from 'emotion';

const drawActionStyle = 
  css`
    display: flex;
    align-items: center;
  `

const styles = makeStyles((theme: IMikeTheme) => { 
  return createStyles({    
    exportTitle: {
      padding: theme.spacing(3) + 'px '+ theme.spacing(2)+ 'px',
      borderTop: '2px solid',
      borderColor: theme.palette.secondary.light
    },
    geometryButtonsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'     
    },
    geometryButton: {
      paddingRight: theme.spacing(1)
    },
    uploadIcon: {
      marginRight: theme.spacing(1)
    },
    pointsContainer: {
      backgroundColor: theme.palette.mediumGrey && theme.palette.mediumGrey.main
    },
    drawStyle: {
      marginLeft: '-10px',
      '& path': {
        fill: '#fff'
      }
    },   
    spinnerRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    uploadingPointsLabel: {
      color: theme.palette.secondary.main,
      paddingLeft: theme.spacing(1)
    }
  })
})

const Points = () => {   
  const classes = styles()
  const dispatch = useDispatch();
  const intl = useIntl();

  const loadingPoints: boolean = useSelector((state: IState) => state.mapContent.loadingConfig)  

  const project: IGetProject | null = useSelector(
    (state: IState) => state.projectContent.project
  );

  const { points, existingPointNames, uploadingPoints }  = useSelector(
    (state: IState) => state.mapContent
  );  

  const { uploadesPointNameLabel, uploadesPointNameHint, showDuplicatePointNamesDialog } = useMemo(() => {
    let names = existingPointNames.join()
    if (existingPointNames.length > 3){
      const firstThreeNames = existingPointNames.filter((name: string, index: number) => index < 3)
      names = firstThreeNames.join() + ",..."
    }
    return { showDuplicatePointNamesDialog: existingPointNames && existingPointNames.length > 0 ? true : false, uploadesPointNameLabel: names, uploadesPointNameHint: existingPointNames.length === 1 ?  intl.formatMessage({id: 'points.oneDuplicatedPointName'}) : intl.formatMessage({id: 'points.manyDuplicatedPointNames'})}
  }, [existingPointNames, intl])

  const [features, setFeatures ] = useState([])

  useEffect(() => {
      setFeatures(points) 
   }, [points]); 

  const onDropPoints = useCallback(
    (files) => {
      if (files.length > 0){
        dispatch(uploadPointsUrlSet(""))
        const file = files[0]   
        dispatch(uploadPoints(file, file.name, project.id)) 
      }
    },
    [dispatch, project])

  const canCreateContent = project && project.capabilities && project.capabilities.canCreateContent

  const onSubmit = (values: IValue, resetElevationIndex = -1) => {
    let vals
    if (resetElevationIndex > -1){
      const editedFeature = values.features[resetElevationIndex]
      const coords = editedFeature.geometry.coordinates
      const editedFeatureWithoutElevation = {...editedFeature, geometry: {...editedFeature.geometry, coordinates: [coords[0], coords[1]]}}
      vals = {...values, features: values.features.map((feature, i) => i === resetElevationIndex ? editedFeatureWithoutElevation : feature)} 
    }
    else{
      vals = values  
    }
    const fixFeatures = vals.features.map((f: any) => {return { ...f, geometry: {type: "Point",
      coordinates: [...f.geometry.coordinates.map((c: string) => parseFloat(c))] } }})
      dispatch(pointUpdate(fixFeatures))
  }

  const handleOnCancel = () => {
    dispatch(setExistingPointNames([],[]))
  }

  const handleRenamePoints = () => {
    dispatch(renamePoints())
  }

  const handleReplacePoints = () => {
    dispatch(replacePoints())
  }

  return (  
    <div>
      <MikeDialog 
        open={showDuplicatePointNamesDialog} 
        onCancel={handleOnCancel} 
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        dialogActions={
          <DialogActions className={dialogActionsStyle}>       
          <MikeButton className={mediumButtonStyle} onClick={handleOnCancel} variant='text'>
            {intl.formatMessage({id: 'points.cancel'})}
          </MikeButton>
          <MikeButton className={mediumButtonStyle} onClick={handleRenamePoints} variant='outlined'>
            {intl.formatMessage({id: 'points.rename'})}
          </MikeButton>
          <MikeButton className={mediumButtonStyle} onClick={handleReplacePoints} variant='contained'>
            {intl.formatMessage({id: 'points.replace'})}
          </MikeButton>
        </DialogActions>
        }
      >
        <MuiDialogContent
          className={dialogContentStyle}
        >
          <Typography
            variant="h3"
            className={contentTitleStyle}
          >
            {uploadesPointNameLabel}
          </Typography>
          <Typography variant="body2">{uploadesPointNameHint}</Typography>
        </MuiDialogContent>

      </MikeDialog>
      <div className={titleContainer}>
        <div>
          <Typography variant="h5" className={titleStyle}>{intl.formatMessage({id: 'points.outputPoints'})}</Typography>
          <div className={drawActionStyle}>
            <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'points.clickOnMapToPlaceAPoint'})}</Typography>
            <KeyboardEditHelp primary geometryType={EGeometryItemTypes.POINT}/>
          </div>
        </div>         
        <div className={classes.geometryButtonsContainer}>
          {uploadingPoints ? <div className={classes.spinnerRow}><CircularProgress size={20}/><div className={classes.uploadingPointsLabel}>{intl.formatMessage({id: 'points.uploading'})}</div></div> :
          <UploadButton disabled={!canCreateContent} fileType={['.csv', '.txt', '.xy', '.xyz']} label={intl.formatMessage({id: 'points.uploadXYZ'})} onDrop={onDropPoints}/> }
          <ContextHelp helpTexts={[
            intl.formatMessage({id: 'points.infoUpload'}),
            "-------------------------------------------------------------------------------------",
            intl.formatMessage({id: 'points.infoUpload4'}),
            intl.formatMessage({id: 'points.infoUpload5'}),
            "-------------------------------------------------------------------------------------",          
            intl.formatMessage({id: 'points.infoUpload2'}),
            intl.formatMessage({id: 'points.infoUpload1'}),
           
          ]}/>
        </div>
      </div>
      
      
      <div>
        {loadingPoints ? <CircularProgress/> :         
          <EditPointsForm
            initialValues={{newName: "", newLong: "", newLat: "", features: features}}
            onSubmit={onSubmit}        
            />
          } 
        </div> 
    </div>
  )
}

export default Points