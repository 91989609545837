import { backend } from './support'
import { IPoint } from '../model/IPoint'
// import scenarios from '../model/mock/scenarios';

export interface IInterpolateMesh {  
  variableId: string;
  propertyName?: string;
}

export const COASTAL = 'Coastal'

export const getGWM = (projectId: string) => {
  return backend.getJson(`input/meshes/data/globalwavemodeldomain/${projectId}`, "1")
}

/* // Removed from backend as we did not get it to does not work properly   -ßßß
export const getIsMeshWithinGWM = (projectId: string) => {
  return backend.getJson(`input/meshes/mesh/check/${projectId}`, "1")
} */

export const getDotMeshIsValid = (projectId: string, datasetId: string) => {
  return backend.getJson(`input/meshes/mesh/dotmeshisvalid/${projectId}/${datasetId}`, "1")
}

export const getScenarios = () => {
  return backend.getJson(`input/meshes/workspace/scenarios/list`, "1")
}

// Create FWE workspace. Returns extent valid for projection system. Invokes 'DeleteWorkspace' if present
// Tag the FWE workspace for finding and deleting it.
// A FWE-Config has not more than one corresponding MeshBuilder Workspace
// Called when the user changes the coordinate system or scenario.
export const createWorkspace = async (srid: number, projectId: string, scenario: string) => {  
  return backend.postJson(`input/meshes/workspace`, {epsgCode: srid, projectId: projectId, scenario: scenario}, "1")
}

export const addDomain = async (projectId: string, datasetId: string) => {
  return backend.postJson(`input/meshes/domain/${projectId}/${datasetId}`, {}, "1")
}

export const addDrawnDomain = async (projectId: string, geojson: string) => {
  return backend.postJson(`input/meshes/domain/${projectId}`, geojson, "1")
}

export const addOwnShoreline = async (projectId: string, datasetId: string) => {
  return backend.postJson(`input/meshes/shoreline/${projectId}/${datasetId}`, {}, "1")
}

export const addDrawnShoreline = async (projectId: string, geojson: string) => {
  return backend.postJson(`input/meshes/shoreline/${projectId}`, geojson, "1")
}

export const deleteOwnShoreline  = async (projectId: string, datasetId: string) => {
  return backend.delJson(`input/meshes/shoreline/${projectId}/${datasetId}`, "1")
}

export const addAOI = async (projectId: string, datasetId: string) => {
  return backend.postJson(`input/meshes/areaofinterest/${projectId}/${datasetId}`, {}, "1")
}

export const addDrawnAOI = async (projectId: string, geojson: string) => {
  return backend.postJson(`input/meshes/areaofinterest/${projectId}`, geojson, "1")
}

export const addBathymetry = async (projectId: string, datasetId: string) => {
  return backend.postJson(`input/meshes/bathymetry/${projectId}/${datasetId}`, {}, "1")
}

export const getWorkspace = (projectId: string) => {
  return backend.getJson(`input/meshes/workspace/${projectId}`, "1")
}

export const canCreateMesh = (projectId: string) => { 
  return backend.getJson(`input/meshes/mesh/cancreate/${projectId}`, "1")
}

export const meshIsExported = (projectId: string) => { 
  return backend.getJson(`input/meshes/mesh/dotmeshexists/${projectId}`, "1")
}

export interface IAddWorkspaceDataset{
  id: string;
  projectId: string;
  usageType: string;
}

export interface IWorkspaceGeometry {
  id: string; 
  name: string;
  dataset: string;
  updated: string;
  itemType: string;
}

export interface IWorkspaceVariable {
  id: string; 
  name: string;
  dataset: string;
  updated: string;
}

export interface IWorkspaceMesh {
  id: string; 
  name: string;
  dataset: string;
  updated: string;
}

export interface IPatchWorkspaceDatasetResponse{
  datasets: Array<IAddWorkspaceDataset>;
  geometries: Array<IWorkspaceGeometry>;
  variables: Array<IWorkspaceVariable>;
  meshes: Array<IWorkspaceMesh>;
}

export interface IUploadedPoint {
  name: string;
  x: number;
  y: number;
}

export const getVtpForGISVectorDataset = async (datasetId: string, espsgCode: string) => {
  return backend.getJson(`input/meshes/background/${datasetId}/${espsgCode}`, "1")
}

// Executes mesh creation returns VtkItemId. 
// Called when the user presses the Create mesh button 
// Returned VtkItemId is input for visualisation request
export const createAutoMesh = async (projectId: string, body: any) => {
  return backend.postJson(`input/meshes/mesh/create/${projectId}`,body, "1")
}

export const getScenarioOptions = async (scenarioName = COASTAL) => {
  return backend.getJson(`input/meshes/workspace/scenarios/${scenarioName}/options`, "1")
}

export const getScenarioParameters = async (scenarioName = COASTAL) => {
  return backend.getJson(`input/meshes/mesh/create/${scenarioName}/parameters`, "1")
}

export const getAutoMeshPayload = async (scenarioName = COASTAL) => {
  return backend.getJson(`input/meshes/mesh/create/info/${scenarioName}`, "1")
}

// Execute interpolation (pass Bathymetry - VtkItemIds). Also delete .mesh file
// Called when the user presses the Interpolate button. 
export const interpolateAutoMesh = async (projectId: string, bathymetries: Array<IInterpolateMesh> ) => {
  return backend.postJson(`input/meshes/mesh/interpolate/${projectId}`, bathymetries, "1")
}

export const deleteDomain = (projectid: string) => {
  backend.delJson(`input/meshes/domain/${projectid}`, "1")
}

export const deleteAOI = (projectid: string) => {
  backend.delJson(`input/meshes/areaofinterest/${projectid}`, "1")
}

export const deleteBathymetry = (projectid: string, vtkItemId: string) => {
  backend.delJson(`input/meshes/bathymetry/${projectid}/${vtkItemId}`, "1")
}

// Return vtp or vti to visualize workspace datasets
export const getWorkspaceData = async (projectId: string, vtkItemId: string) => {
  return backend.getJson(`input/meshes/data/${projectId}/${vtkItemId}`, "1")
}

// Return vtp to visualize mesh uploaded by the user
export const getMesh = async (datasetId: string) => {
  return backend.getJson(`input/meshes/${datasetId}`, "1")
}

export const getDataExists = async (projectId: string, vtkItemId: string) => {
  return backend.getJson(`input/meshes/dataExists/${projectId}/${vtkItemId}`, "1")
}

export const processContainer = (projectId: string, jobIndex: string) => {
  return backend.postJson(`job/${projectId}/container/${jobIndex}`, {}, "1")
}

export const getStoredJobStatus = (projectId: string) => {
  return backend.getJson(`job/status/${projectId}`, "1")
}

export const saveJobStatus = (status) => {
  return backend.postJson(`job/status`,status, "1")
}

export const getJobLogs = (projectId: string, jobId: string) => {
  return backend.getJson(`job/log/${projectId}/${jobId}`, "1")
}

export const getLatestFastwaveConfig = (projectId: string) => {
  return backend.getJson(`input/config/${projectId}`, "1")
}

export const validateFastwaveConfig = (projectId: string) => {
  return backend.getJson(`input/config/${projectId}/validate`, "1")
}

export const getPointZValues = (projectId: string, datasetId: string, points) => {
  return backend.postJson(`outputpoints/addz/${projectId}/${datasetId}`, points, "1")
}

export const transformPoints = (xyzFileBlobUrl: string, srid: number, meshFileDatasetId: string) => {
  return backend.postJson(`outputpoints/transform?xyzFileBlobUrl=${xyzFileBlobUrl}&srid=${srid}&meshFileDatasetId=${meshFileDatasetId}`, {}, "1")
}

export const getCentralPoints = (projectId: string) => {  
  return backend.getJson(`centralpoints?projectId=${projectId}`, "1")
}

export const getChartIntialData = (projectId: string, id: string) => {
  return backend.getJson(`extracteddata/${projectId}/${id}`, "1")
}

export const getChartData = (projectId: string, id: string, xId: string, yId: string) => {
  return backend.getJson(`extracteddata/${projectId}/${id}/${xId}/${yId}`, "1")
}

export const getBoundaryLines = (projectId: string, datasetId: string) => {
  return backend.getJson(`boundary/list?projectId=${projectId}&datasetId=${datasetId}`, "1")
}

export const getEvents = (projectId: string) => {  
  return backend.getJson(`events/${projectId}`, "1")
}

export const postEvents = (projectId: string, events: Array<string>) => {
  return backend.postJson(`events/${projectId}`, events, "1")
}

export const getTotalExplainedVarianceRatio = (projectId: string) => {  
  return backend.getJson(`events/${projectId}/totalexplainedvarianceratio`, "1")
}

export const checkMeshContainsPoints = (projectId: string, datasetId: string, points: Array<IPoint>) => {
  return backend.postJson(`boundary/contains/${projectId}/${datasetId}`, points, "1")
}

export const getSetups = () => {  
  return backend.getJson(`setups`, "1")
}

export const downloadSetupToProject = (projectId: string, setup: string) => {
  return backend.postJson(`setups/${setup}/${projectId}`, {}, "1")
}

export const getOwnEnvironmentalDataTemplate = () => {
  return backend.getJson(`environmentalData`, "1")
}

export const uploadOwnEnvironmentalData = (projectId: string, uploadUrl: string) => {
  return backend.postJson(`environmentalData/upload/${projectId}`, uploadUrl, "1")
}
