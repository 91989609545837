import { flattenMessages } from "./utils";

const messages = {  
  openIdStatusPage: {
    headline: "Authenticating",
    pleaseWait: "Please wait...",
    loading: "Loading",
    errorHeadline: "Authentication failed",
    errorBody: "Please try again.",
    retry: "Retry"
  },
  app: {
    title: "MIKE Metocean Simulator",
    registeredName: "Fast Wave Emulator",
    newRegisteredName: "Metocean Simulator",
    goToFWE: "Start Simulator"
  },
  supportEmail: {
    subject: "MIKE Metocean Simulator Request",
    body: "Please add relevant details about your request."
  },
  landingPage: {
    buttonText: "Go to login",
    header: "MIKE Metocean Simulator",
    linkLabel: "Unsure about how to access? ",
    linkText: "Contact us. "
  }, 
  createFolder: {
    name: "Name",
    description: "Description",
    createFolder: "Create folder",
    title: "Create folder"
  },
  editFolder: {
    editFolder: "Update folder",
    title: "Update folder",
    advancedEditingHint: "Advanced editing can be done in",
    cloudAdmin: "Cloud Admin"
  },
  sagas: {
    auth: {
      signedIn: " is signed in",
      fetchingData: "Fetching data",
      doneDataFetching: "Data has been fetched"
    }
  },
  common: {     
    dataset: "Dataset",      
    description: "Description",    
    editedAt: "Edited",
    folder: "Folder",    
    lastActivityAt: "Last activity",
    locationType: "Location type",    
    name: "Name",       
    project: "Project",
    projects: "Projects", 
    type: "Type",
    updatedAt: "Updated"
  },
  projects: {
    loadError: "Failed to load projects",
    deleteManyConfirmation: "Do you really want to delete selected projects?",
    deleteOneConfirmation: "Do you really want to delete selected project?",
    delete: "Delete"
  },
  project: {
    loadError: "Failed to load project",
    noAccess: "You do not have access to current project with id",
    noWriteAccess: "You do not have write access to current project with id",
    noReadAccess: "You do not have read access to current project with id",
    contactOwner: "If you think this is a mistake please contact the project owner.",
    deleteManyConfirmation: "Do you really want to delete selected folders?",
    deleteOneConfirmation: "Do you really want to delete selected folder?",
    delete: "Delete"
  },
  folders: {
    loadError: "Failed to load folders"
  },
  datasets: {
    loadError: "Failed to load datasets"
  },
  nav: {
    initialselection: "Initial selection",
    createmesh: "Mesh & bathymetry",
    points: "Points",
    boundaryConditions: "Boundary conditions",
    qualityControl: "Nodes selection",
    setupAndRun: "Setup & run"
  },
  feature: {   
    name: "Name",
    longitude: "X coordinate",
    latitude: "Y coordinate", 
    zCoordinate: "Elevation [m]", 
    unvalidGeometry: "Please upload point geometries.",
    unvalidCSV: "Please upload csv with space or comma separated coordinates. Expected format is 'x,y' or 'x y'",
    ensureUniqNames: "Please ensure that the point names are unique",
    deleteAll: "Clear",
  },
  advancedSettings: {
    error: "Please make the desired dimensions smaller than the number of nodes",
    title: "Dimensional reduction and number of model runs",
    subTitle: "Specify number of dimensions used in pricipal component analysis and number of nodes in maximum dissimilarity algorithm",
    desiredDimensionHelpText: "Select a higher number of dimensions such that the variance in the data can be explained.",
    maxDissimilarityHelpText1: "A node is a timestep selected from the timeseries to be solved using the MIKE 21 engine.",
    maxDissimilarityHelpText2: "The solved results for the selected nodes are used to interpolate the full timeseries of defined events.",
    maxDissimilarityHelpText3: "A high number of nodes will increase the accuracy but also increase the run time of the Simulator.",
    maxDissimilarityHelpText4: "For a short timeseries, the number of selected nodes can be the same as the number of timesteps, requiring no interpolation.",
    maxDissimilarityHelpText5: "A low number of nodes (15) is generally only suitable for testing."
  },
  setup: {
    advancedSettings: "Advanced Global Settings",
    editDimensions: "Edit dimensions and variance",
    noModelRun: "Number of nodes",
    desiredDimensions: "Desired dimensions",
    desiredDimensionsUsedInPrincipalComponentAnalysis: "Desired dimensions used in principal component analysis",
    resultingDimensions: "Resulting dimensions",
    variance: "Variance",
    maxDissimilarity: "Number of nodes selected from timeseries",
    applySettings: "Apply settings",
    min: "min",
    max: "max",
    title: 'Setup selection',
    subTitle: 'Select pre configured setup or upload your own',
    chart: {
      legend: {
        title: 'Selected nodes / timesteps',
        subTitle: 'Click on a node on the chart to select the timestep for a test run'
      }
    },
    runTestButton: 'Run Test',
    missingData: {
      title: 'Data for selecting nodes / timesteps has not been created yet',
      subTitle: 'Data will be created once you press Apply settings in previous step. A test model run can be executed anyhow.'
    },
    processingData: {
      title: 'Processing data for selecting nodes / timesteps',
      subTitle: 'Please wait until data processing has finished'
    },
  },
  testRunTable: {
    scatterPlots: "Scatter plots of selected parameters at selected boundary or forcing",
    clickOnNodes: "Click on nodes in plot to adapt selected nodes",
    noTestRuns: "No test runs to visualize.",
    clickOnANode: "Click on a node on the chart on the left",
    toSelect: "to select the timestep for a",
    testRun: "test run",
    resultOfTestRun: "Result of test run"
  },
  viewerLegend: {
    layers: "Layers"
  },
  projectExplorer: {
    name: 'Name',
    type: 'Type',
    format: 'Format',
    srid: 'Projection system',
    updatedAt: 'Edited',
    na: 'N/A',
    epsg: 'EPSG',
    okButtonLabel: 'Select',
    cancelButtonLabel: 'Cancel',
    title: {
      selectMesh: 'Select mesh',
      selectMeshOutline: 'Select mesh outline',
      selectAOI: 'Select area of interest',
      selectExtraction: 'Select extraction (.zip)',
      selectBathymetry: 'Select bathymetry',
      selectPoints: 'Select points',
      selectShoreline: 'Select shoreline',
      selectBackground: 'Select background'
    }
  },
  successDialog: {
    dataReady: "Model results are ready",
    dataCanBeDownloaded: "Model results can be downloaded",   
    orModelResultsCanBePublished: "or published to",
    toMOOD: "MetOcean On Demand (MOOD)"
  },
  sideBar: {
    downloadEvents: "Download selected nodes (events.json)",
    downloadPoints: "Download point coordinates as xy file",
    downloadMesh: "Download mesh as .mesh file",  
    downloadResults: "Download results", 
    completedOn: "from",
    asCSV: "as csv",
    or: "or",
    asDFS0: "as dfs0",        
    publishToMOOD: "or publish model results to",
    mood: "MetOcean On Demand (MOOD)",
    cancelJob: "Cancel job",
    back: "Back",
    runModel: "Run model",
    nextStep: "Next step"
  },
  qualityControl: {
    missingData: {
      title: 'Data for nodes selection has not been created yet',
      subTitle: 'Please define your desired settings in section above. Data will be created once you press Apply settings.'
    },
    processingData: {
      title: 'Processing data for nodes selection',
      subTitle: 'Please wait until data processing has finished'
    },
    title: 'Scatter plots of selected parameters at selected boundary or forcing',
    subTitle: 'Click on nodes in plot to adapt selected nodes'
  }, 
  jobProgress: {
    starting: 'Starting job...',
    pending: 'Job is pending...(approx 2 min)',
    running: 'Job is running....',
    finished: 'Job finished',
    isStarting: 'Starting ',
    isPending: ' is pending...(approx 2 min)',
    isRunning: ' is running....',
    isFinished: ' finished',
    isCancelled: ' cancelled',
    approx: 'approx',
    minutes: 'min',
    canTakeSeveralMinutes: '(can take several minutes)',
    canTakeHours: '(can take hours)'
  },
  jobErrors: {
    getStatus: 'Job failed.'
  },
  projectionSystemDialog: {
    xyReader: {
      title: 'Projection for your XY file',
      question: 'Are xy coordinates in the same projection as the mesh?',
      yes: 'Yes, xy coordinates are in the same projection.',
      no: 'No, I need to select the projection.',    
    },
    zippedShapeReader: {
      title: 'Projection of your zipped shapefile',
      question: 'Is the projection of your shapefile defined by a prj file inside the zip file? (You can select the projection anyhow - some projections cannot be recognized automatically from prj file).',
      yes: 'Yes, please try to use the prj file inside the zip file.',
      no: 'I need / would like to select the projection myself.',      
    },
    bathymetryReader: {
      title: 'Properties of your xyz file',
      question: 'Are coordinates in target projection?',
      yes: 'Yes, coordinates are in target projection.',
      no: 'No, I need to select the projection.',
    },
    pickProjection: 'If not, check respective option and use field below to pick their projection.'
  },
  warnings: {
    pleaseConfirm: 'Please confirm',
    outputAndConfigDeleted: 'Therefore, your configuration will be reset. If an output directory exists, it will be deleted.',
    confirmDialogContentTitle: 'Your change will invalidate your configuration and any existing results.',
    projectionReferenceDeleted: 'Previous projection reference will be deleted',
    noAccessToProject: 'You do not have read access to this project',
    deleteItem: 'Do you really want to delete the item?',
    resetMesh: 'Your change will reset your mesh input',
    submitDrawnGeometry: 'Do you want to submit your drawn geometry?',
    change: 'Change',
    runModel: 'You are about to execute simulations for each of the selected nodes. This will activate a cloud computer. MIKE MS does not currently monitor the cloud execution credits automatically and therefore it will not consume any of your available cloud credits. DHI will monitor the cloud computer usage and accepts that the user can continue with reasonable use of cloud resources. The reasonable usage is deemed by DHI to be about 15 simulation hours per month. This will give the user access to run about 7 simulations similar to the setup in the step-by-step user guide. MIKE MS may start automatic tracking of cloud execution credits in the future.',
    runModelButton: 'Run model',
    backButton: 'Back',
    startCloudExecution: 'Start cloud execution',
    gwmWarningTitle: 'Mesh outside coverage of boundary dataset',
    gwmWarningConfirm: 'To extract data anyhow press Extract data.',
    pendingGwmCheckTitle: 'Pending data coverage check',
    pendingGwmCheckMessage: 'Still checking if mesh is within coverage of boundary dataset...',
    tryAgainInAFewMinutes: 'Please try again in a few minutes.'
  },
  initialSelection: {
    meshingOptions: 'Meshing options',
    selectOption: 'Select one of the below options',
    useAutoMeshingOption: 'Use the assisted meshing option',
    useOwnMeshOption: 'Use your own mesh',
    targetProjectionSystem: 'Target projection system EPSG code',
    noMeshUploaded: 'No mesh has been uploaded yet',
    byDefaultCoordinatesAreInLongLat: 'By default coordinates are in EPSG code 4326, also known as WGS84 projection (LONG; LAT coordinates)',
    selectScenario: 'Scenario',
    ownMeshSubNote1: 'Create (and export) your own mesh in',
    meshBuilder: 'Mesh Builder',
    ownMeshSubNote2: 'or in the Mesh Generator (Desktop)',
    ownMeshSubNote3: 'and upload the mesh from Cloud Admin or from the desktop respectively',
    scenarioCoastalDesc: 'Coastal: Part of the external model boundary is closed by a shoreline.',
    scenarioOffshoreDesc: 'Offshore: There is no shoreline on any external model boundary. All external boundaries are open.',
  },
  createMesh: {   
    parameterInfo_NumberOfDensityPoints: 'The number of density points is the target number of mesh points to be generated in the domain. The mesh points are placed at a higher density in shallow water, based on the bathymetry data.'    
  },
  boundaryConditions: {
    source: 'Source',
    createdOn: 'Created on',
    selectFromPreviousExtractions: 'Or select from previous extractions/uploads',
    apply: 'Apply',
    providers: {
      wind: 'Wind',
      waves: 'Waves: Swell & Wind-sea',
      waterLevels: 'Water levels'
    }, 
    noProviderDataAvailableYet: 'No provider data available yet.',   
    title: 'Environmental data to use',
    subTtile: 'Confirm or select environmental data to extract',
    extractDataFromOurProviders: 'Extract data from one of our providers',
    useYourOwnData: 'Or use your own environmental data',
    uploadedTemplate: 'Uploaded template',
    downloadTemplate: 'Download template (.csv)',
    uploadTemplate: 'Upload template',
    replaceTemplate: 'Replace template',
    ownDataHintPart1: 'To use your own data as forcings,',
    ownDataHintLink: 'download the template csv file,',
    ownDataHintPart2: 'fill it in and upload it.',   
    click: 'Click',
    instructionsForUsingFWEDataTemplate: 'Instructions for using MIKE MS data template',
    forMoreDetailedInstructions: 'for more detailed instructions',
    startDate: 'Start date',
    endDate: 'End date',
    extractData: 'Extract data',
    uploadInstructions: {
      title: 'Instructions for using the MIKE MS data template.',
      line1: '1. First, click on the download link to get the csv template file.',
      line2: '2. Once the template is downloaded, open it up in Microsoft Excel or another spreadsheet program.',
      line3: '3. Fill in the required information in the designated cells of the template. Be sure not to change headings or placements of rows.',
      line4: '4. Save the filled-in template file to your computer or device. Be sure not to change file format (.csv)',
      line5: '5. Next, click on the upload button.',
      line6: '6. Select the filled in template.',
      okButton: 'Ok',
      cancelButton: 'Cancel'
    },
    ownDataHintPopup: {
      title: 'Uploading your own data',
      line1: 'In order to use this option, you need to download the template csv file provided by DHI, and upload the filled in template.',
      see: 'See',
      madeAvailable: 'made available below template field',
      okButton: 'Ok',
      cancelButton: 'Cancel'
    },
    ownDataHints: {
      editAndReUplaod: "Please edit and re-upload your data file"
    },
    uploadDataPopup: {
      title: 'Upload data',
      line1: 'Before uploading the data make sure that it follows the below mentioned requirements:',
      line2: 'The file format is .csv',
      line3: 'The spreadsheet is following our template one to one',
      line4: 'There has been done no changes in headings or column placement',
      okButton: 'Upload',
      cancelButton: 'Cancel'
    }
  },
  chartContainer: {
    pleaseKeepAtLeast: 'Please keep at least',
    nodesSelected: 'nodes selected',
    pleaseDoNotSelectMoreThan: 'Please do not select more than',
    nodes: 'nodes',
    selectBoundaryOrForcing: 'Select boundary or forcing',
    axisConfig: 'Axis configuration',
    xLabel: 'X',
    yLabel: 'Y'
  },
  editPointsForm: {
    point: 'Point',
    xCoord: 'X coordinate',
    yCoord: 'Y coordinate',
    zCoord: 'Elevation [m]'
  },
  autoMesh: {
    gebco_hint: 'Gebco bathymetry will be provided during domain creation',
    xyz_hint: 'XYZ data needs to include at least three points.',
    priority_hint: 'Only the bathymetry data with the highest priority (lowest numerical value) is used in a mesh element for interpolation',
    domain_infoText: 'The area to be modeled',
    shoreline_infoText: 'Must start and end end outside domain',
    aoi_infoText: 'The area in your domain that should have a refined mesh',
    upload: 'Upload',
    delete: 'Delete',
    parameter: 'Parameter',
    draw: 'Draw',
    mesh: 'Mesh',
    outlineAndAOI: 'Outline domain and area of interest',
    shoreline: 'Shoreline', 
    noShoreline: 'There is no shoreline.  To add, draw shoreline on the map, copy from Cloud Admin or upload polyline shape file',  
    outlineShoreline: 'Draw or upload the shoreline', //'Load NOAA coastline or upload your own shoreline (optional)',
    outline: 'Domain',
    outlineTheDomain: 'Outline the area of your domain',
    noOutlineDomain: 'There is no outline domain. To add, draw outline on the map, copy from Cloud Admin or upload polygon shape file',
    aoi: "Area of interest",
    outlineAOI: 'Outline the area in your domain that should have a refined mesh',
    bathymetry: 'Bathymetry',
    loadGebcoOrOwn: 'GEBCO bathymetry provided by Metocean Simulator. User can optionally upload additional bathymetry data',
    noAOI: 'There is no area of interest. To add, draw outline on the map in your domain, copy from Cloud Admin or upload polygon shape file',
    uploadShapefile_hint: 'The following files must be uploaded together in a zipped file: .shp, .shx, .prj, .dbf',
    copyFromPlatform_hint: 'Copy files uploaded to Cloud platform, e.g. by Cloud Admin',
    addBathymetry: 'Add bathymetry',
    creatingMesh: 'Creating mesh',
    interpolating: 'Interpolating',
  },
  outlineProperties: {
    parameters: 'Parameters',
    adaptDefaultValues: 'Adapt default values',
    submitChange: 'Submit change'
  },
  points: {
    outputPoints: 'Output points',
    clickOnMapToPlaceAPoint: 'Click on map to place a point',
    uploadXYZ: 'Upload points',
    infoUpload: 'Points can be uploaded as comma separated text file (name,x,y), e.g.:',
    infoUpload1: 'The file should not include a header.',
    infoUpload2: 'Each row should start with an unique name followed by x and y coordinates.',
    infoUpload3: 'Sample:',
    infoUpload4: 'Point of interest,16.854213,34.897379',
    infoUpload5: 'Another point of interest,18.371258,34.502409',
    oneDuplicatedPointName: 'A point with this name already exists. Would you like to replace or rename the existing one or cancel the upload?',
    manyDuplicatedPointNames: 'Points with these names already exist. Would you like to replace these existing ones, rename them or cancel the upload?',
    rename: 'Rename',
    replace: 'Replace',
    cancel: 'Cancel',
    uploadError: 'Point upload failed. Please try again.',
    uploading: 'Uploading Points'
  },
  platform: {
    replace: 'Replace from Cloud Admin',
    copy: 'Copy from Cloud Admin',
    cloud: 'Cloud'
  },
  desktop: {
    replace: 'Replace by upload',
    upload: 'Upload File',
    desktop: 'Desktop'
  },
  draw: {
    replace: 'Replace by drawing',
    onMap: 'Draw',
    DRAW_BUTTON_CLICK: 'Press the Draw button to start drawing',
    PANEL_CLICK: 'Click on the panel to close me and start drawing',
    KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING_POINT: 'Click on the map to add a point',
    KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW: 'Pan while drawing',
    SHIFT: 'SHIFT',
    SCROLL: 'SCROLL',
    KEY_SCROLL: 'Zoom in / out',
    KEY_MOUSE_LEFT_PRESS_DRAW: 'Click on the map to add vertices',
    KEY_MOUSE_DOUBLE_LEFT_PRESS_DRAW: 'Double click to complete current polygon/polyline',
    KEY_SPACE_DRAW: 'Press SPACE to complete current polygon/polyline',
    KEY_SPACE: 'SPACE',
    KEY_BACKSPACE_DRAW: 'Delete last point from current polygon/polyline', 
    KEY_ESC_DRAW: 'Cancel current polygon/polyline',
    KEY_BACKSPACE: 'BACKSPACE',
    KEY_ESC: 'ESC',   
  },
  validation: {
    allowedAre: 'Allowed are',
    dotMesh: 'Failed to validate .mesh file. Please generate/upload a new mesh.'
  }
};

export default flattenMessages(messages);
